.news-content {
  &.dark-mode{
    @include background-colors($light-theme:false);
    .scrollable .article-column{
      border-right: 1px solid  #b3b3b3 !important;
    }
    >*, div, p, a{
        @include theme-colors($light-theme: false);
    }
  }
  a {
    color: #000;
    text-decoration: none;
  }
  background: url("../static/images/news/newspaper-bg-tile.png") repeat;
  font-family: Georgia;
  font-size: 12px;
  min-height: 900px;
  text-align: justify;
  .main-content {
    .article {
      .title p{
        font-size: 24px;
        text-align: center;
      }
    }
  }
  .divider {
    height: 16px;
  }
  .excerpt,
  .article {
    a .title.headline {
      p {
        font-family: Georgia, "Times New Roman", Times, serif !important;
      }
      &.left.viewpoint p,
      &.center.lincvolt p,
      &.center.tech p,
      &.right.tech p
      {
        font-family: "Georgia", serif!important;
      }
      &.right.viewpoint p {
        font-family: "Oswald",serif!important;
      }
      &.right.lincvolt p {
        font-family: "Roboto",serif!important;
      }
      &.center.subscribers-news p {
        font-family: "Georgia",serif!important;
      }
      &.left.tech p {
        font-family: "DINProCondensed",serif!important;
      }
    }


      .title,
      .title-column {
        & iframe,
        & img {
          width: 100%;
          height: auto;
        }
      }
      .article-text,
      .newsArticle {
        & iframe,
        & img {
          width: 100%;
          height: auto;
        }
      }
  }

  .article-column {
    font-size: 14px;
    padding: 0px;
    .excerpt {
      .title-column p,
      .title p {
        width: 100%;
        text-align: justify;
        font-size: 14px !important;
        line-height: 14px !important;
      }
      .excerpt-content p {
        font-size: 10px !important;
        line-height: 10px !important;
        margin-top: 10px !important;
      }
      .excerpt-divider {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAdsAAAAFAQAAAAAlQntyAAAAAnRSTlMAAQGU/a4AAAASSURBVHgBY6AI/CcfPBiiNgMAHBR3S+AvNgQAAAAASUVORK5CYII=");
        height: 4px;
        background-position: 50%;
        background-repeat: repeat-x;
        max-width: 100%;
      }
    }
    .column-title {
      iframe,
      img {
        width: 100%;
      }
    }
  }
}

.news-content {
  overflow-x: scroll;
  $column-width: 250px;

  .scrollable {
    display: grid;
    grid-gap: 20px;
    scroll-snap-type: x proximity;
    &:before,
    &:after {
       content: '';
    }
    .article-column:not(:last-child) {
      border-right: 1px solid black;
      padding-right: 20px;
    }
  }
}

.news-content {
  .article-column .column-title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }
}

.news-content {
  .article-column {
    .title.headline p {
      line-height: 1.25!important;
      font-family: Georgia, "Times New Roman", Times, serif !important;
    }
  }
}

.viewpoint,
.subscribers-news,
.lincvolt,
.tech {
  &.title.headline {
    font-family: "Georgia", Times, serif !important;
    font-size: 14px;
    p {
      text-align: left!important;
      font-weight: 700;
      font-style: normal;
    }
  }
}

.left.tech {
  font-family: "Sixcaps";
}

.right.tech {
  font-family: Georgia;
  font-style: italic;
  font-weight: 400 !important;
}

h1#viewpoint {
  font-size: 16px!important;
}
