#leftside-icons-wrapper {
  background-color: #4c311b;
  height: inherit;
  @media only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait)
  and (-webkit-min-device-pixel-ratio: 2){
  height: 100vh;
}
}
.left-side-icons {
  width: 100%;
  .row {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: inherit !important;
    width: 100%;
    &.small {
      width: 50%;
      float: left;
    }
    &.small,
    &.side-to-side {
      .image {
        width: 120px;
        height: auto;
        font-size: 1rem;
      }
    }
  }
  .icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 400px;
    height: inherit;
    margin-bottom: 10px;
  }
}
