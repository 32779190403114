.tickets-panel{
    overflow-x: hidden;
    overflow-y: scroll;
    align-items: center;
    width: 100%;

    .ticket {
        width: 100%;
        height: 200px;
        position: relative;
        box-sizing: border-box;

        &::before {
            content: '';
            position: absolute;
            display: block;
            top: -20px;
            right: -20px;
            bottom: -20px;
            left: -20px;
            background-color: #232323;
            z-index: -1;
        }

        .loading-indicator-wrapper {
            $dur: 2s;
            -webkit-animation:spin $dur linear infinite;
            -moz-animation:spin $dur linear infinite;
            animation:spin $dur linear infinite;
            border: 1px solid transparent;
            width: 8px; height: 8px;

            position: absolute;
            top: 442px;
            right: 165px;

            .loading-indicator {
                opacity: 0.7;
                background-image: url('../static/images/new-player/loading-indicator.png');
                height: 46px;
                width: 48px;
                position: relative;
                top: -20px;
                left: -20px;
            }
        }

        .month {
            top: 60px;
            left: 23px;
            position: absolute;
            color: #3d382e;
            font-family: "Microbrew One", "Eveleth Slant Regular";
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: 7px;
        }

        .number {
            position: absolute;
            text-align: center;
            color: #3d382e;
            font-family: "Microbrew One", "Eveleth Slant Regular";
            font-size: 55px;
            line-height: 123px;
            letter-spacing: -3px;
            top: 40px;
            left: 25px;
        }

        .year {
            position: absolute;
            color: #3d382e;
            font-family: "Microbrew One", "Eveleth Slant Regular";
            font-size: 25px;
            letter-spacing: 1px;
            top: 123px;
            left: 19px;
        }

        .ticket-button {
            position: absolute;
            top: 110px;
            right: 27px;
            min-width: auto;
            width: 100px;
            color: #fff;
            background-color: #035ca7;
            border-radius: 10px;
            box-sizing: border-box;
            border: 2px solid transparent;
            font-size: 9px;
            line-height: 32px;
            font-family: DINProBold!important;
            letter-spacing: 2.2px;
            text-align: center;
        }

        .code-button {
            position: absolute;
            top: 148px;
            right: 28px;
            min-width: auto;
            width: 100px;
            color: #fff;
            background-color: #035ca7;
            border-radius: 10px;
            box-sizing: border-box;
            border: 2px solid transparent;
            font-size: 9px;
            line-height: 32px;
            font-family: DINProBold!important;
            letter-spacing: 2.2px;
            text-align: center;
        }

        .loading-indicator {
            position: absolute;
            background-size: contain;
            top: 420px;
            right: 150px;
            height: 50px;
            width: 50px;
        }

        .ticket-title {
            position: absolute;
            top: 10px;
            left: 75px;
            color: #3d382e;
            font-family: "Microbrew One", "Eveleth Slant Regular";
            font-size: 25px;
            line-height: .9;
            letter-spacing: -1.75px;
            max-width: 680px;
        }

        .ticket-descrOne {
            position: absolute;
            top: 67px;
            left: 75px;
            color: #3d382e;
            font-family: "Microbrew One", "Eveleth Slant Regular";
            font-size: 15px;
            letter-spacing: -0.87px;
            max-width: 220px;
        }

        .ticket-descrTwo {
            position: absolute;
            top: 110px;
            left: 70px;
            flex-direction: column;
            width: 131px;

            & > label {
                color: #3d382e;
                font-family: "Microbrew One", "Eveleth Slant Regular";
                font-size: 15px;
                line-height: 24px;
                letter-spacing: -1.69px;
                max-width: 100px;
            }
        }

        .ticket-codeSection {
            position: absolute;
            top: 10rem;
            left: 5.5rem;
            max-width: 200px;
            display: flex;
            flex-direction: column;

            & > label {
                color: #3d382e;
                font-family: "DINProCondensedBold";
                font-size: 14px;
                line-height: 1px;
                .code{
                    font-size: 16px;
                }
            }

            .code {
                color: #035ca7;
                font-family: "DINProCondensedBold";
                font-size: 45px;
                line-height: 45px;
                user-select: text;
            }
        }

        &--public {
            background-image: url('../static/images/account-info/public_ticket.png');
            background-size: cover;
            height: 180px;
            .month{
                left: 28px;
            }
            .year{
                left:26px ;
            }

            .ticket-button {
                top: 376px;
            }
            .ticket-descrOne{
                top: 55px;
            }
            .ticket-descrTwo{
                left: 75px;
            }
        }

        &--nya {
            background-image: url('../static/images/account-info/nya_ticket.png');
            background-size: cover;

            .month {
                top: 60px;
                left: 23px;
            }

            .number {
                top: 40px;
                left: 25px;
            }

            .year {
                top: 123px;
                left: 19px;
            }

            .ticket-title {
                top: 30px;
                left: 75px;
                line-height: .9;
            }

            .ticket-descrOne {
                top: 73px;
                left: 75px;
            }

            .ticket-descrTwo {
                top: 110px;
                left: 75px;
            }
        }

        &--soldOut {
            .ticket-descrOne{
                top: 50px;
            }
            .ticket-descrTwo{
                top: 89px;
                left: 76px;
            }
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 180px;
                background-size: cover;
                background-image: url('../static/images/account-info/sold_out.png');
            }
        }
    }
}
// ################# IPAD VIEW ##########################
@media only screen and (device-width: 768px) {
    .tickets-panel{
        display: flex;
        flex-direction: column;
        .ticket{
            width: 50%;
            display: flex;
            flex-direction: column;
            .ticket-codeSection {
                
                top: 11rem;
            }
            .ticket-button{
                top: 123px;
            }
            .code-button {
                top: 163px;
            }
        }
        .ticket--nya .ticket-descrOne {
            top: 63px;
            left: 75px;
        }
        .ticket--nya .ticket-descrTwo {
            top: 92px;
        }
   
    }
}