.preview-page {
    width: 100%;
    height: 100%;
}

#main-wrapper {
    width: 100%;
    height: 100%;

}

#content {
    width: 100%; height: 100%;
    > div { /*the anonymous transition group element.*/
        width: 100%; height: 100%;
        position: relative;
    }

    position: relative;
    overflow:hidden;
}


.content-wrapper {
    position:relative;
    .home-wrapper{
        height: fit-content;
        background-color: #4c311b;
    }
}
div.ui.container{
    margin-left:0rem !important;
    margin-right:0rem !important;
    padding-top: 30px;
    margin-top: 0px;
    .icon-link {
        height: max-content!important;
    }
}
.loading{
    
    height: 100vh;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;  

}
.nextjs-toast-errors-parent{
    display: none;
}

.loading-indicator{
    animation: pulse 4s infinite linear;
}
@keyframes pulse {
    from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
  }

@import 'mixins.scss';
@import 'leftSideIcons.scss';
@import 'account-menu.scss';
@import 'fonts.scss';
@import 'home.scss';
@import 'article.scss';
@import 'sidebar.scss';
@import 'global-top-bar.scss';
@import 'user-info.scss';
@import 'popUp.scss';
@import 'popUp.scss';
@import 'newsNavbar.scss';
@import 'presale.scss';
@import 'plans.scss';
@import 'news.scss';
@import 'contact.scss';
#__next{
    height: 100%;
}
.overlay {
    position: absolute !important;
    top: 0px; left: 0px;
    width: 100%; height: 100%;
    z-index: 1000;

    .body-unscaled & {
        width: 100vw;
        height: 100vh;
    }
}
.auth0-lock-widget {
    * {
        font-family: DINPro;
    }
    .auth0-lock-header {
        min-height: 125px;
    }
    .auth0-lock-header-bg {
        // background: url('../images/auth0/Login.png') !important;
        background-size: contain  !important;
        min-height: 125px !important;
        image-rendering: -webkit-optimize-contrast;
    }
    .auth0-lock-content-wrapper {
        overflow: visible !important;
    }
    .auth0-lock-name {
        display: none;
    }
    .auth0-lock-content, .auth0-lock-tabs, input {
        background-color: #f2ecdc !important;
    }
    .auth0-lock-input-wrap {
        border: 1px solid #ccc7b8 !important;
        background: #ccc7b8 !important;
    }

    .auth0-lock-header-bg-blur {
        display: none !important;
        filter:  none;
    }
}

.auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-overlay {
    opacity: 0.3 !important;
}

.auth0-lock.auth0-lock .auth0-lock-widget {
    overflow: visible !important;
}
.auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-widget{
    transition: none !important;
    // transition: transform 0.6s !important;
    // -webkit-transition: transform 0.6s !important;
}

#auth, #terms {
    display:none;
    position: absolute;
    top:0px; left: 0px;
    width: 100%; height: 100%;
}

#auth {
    z-index: 100;
    background: rgba(0,0,0,0.3);
}

.user-info {
    background-color: rgba($color: #000000, $alpha: 0.9);
    .subscribe{
        background: url("../static/images/global-menu/subscribe-hl.png");
        &.login {
            background: url("../static/images/global-menu/login-hl.png");
        }
    }
    
}

