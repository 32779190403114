.pop__up.modal{
  .content{
    border-image:none;
    border:none;
    border-radius:0px;
    .center.aligned.column{
      display: flex;
      justify-content: center;
      .header{
        font-family: Typewriter !important;
        font-size: 13px;
        line-height: 1.5em;
        color: #403630;
      }
    }
    .eight.wide.column.icon{
      font-family: Typewriter !important;
    }
    .custom-radio{
      input{
        width: 10px;
          height: 10px;
          border: solid 0px;
          margin-bottom: 2rem;
        &:after {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          top: -2px;
          left: -1px;
          position: relative;
          background-color: #d1d3d1;
          content: '';
          display: inline-block;
          visibility: visible;
          border: 1px solid rgb(79, 79, 79);
        }
      &:checked:after{
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #035ca7;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid rgb(79, 79, 79);
      }
    }
   }
  }
}
.pop__up.modal,.pop__up.account{
  .content {
    h1{
      font-family: Roboto,sans-serif !important;
      font-weight: 700;
      letter-spacing: .05em !important;
      margin: -30px -25px 35px;
      padding: 35px 25px 30px;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: normal;
      border-bottom: 1px solid rgba(0,0,0,.5);
      color: #4b4536;
      display: none;
    }
    p{
      color: #4b4536;
    font-family: BohemianTypewriter,serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    }
    .eight.wide.column.icon{
      display: flex;
      justify-content: space-around;
      input{
        transform: scale(2);
      }
    }
  }

}
.pop__up.account{
  background-color:transparent !important;
  background: transparent !important;
  .content {
    border-image:none;
    border:none;
    background: transparent;
    .row.modal-input{
      justify-content: center;
    }
    .center.aligned.column{
      display: flex;
      justify-content: center;
    }
    .custom-radio{
      input{
          width: 10px;
          height: 10px;
          border: solid 0px;
          margin-bottom: 2rem;
        &:after {
          width: 15px;
          height: 15px;
          border-radius: 15px;
          top: -2px;
          left: -1px;
          position: relative;
          background-color: #d1d3d1;
          content: '';
          display: inline-block;
          visibility: visible;
          border: 1px solid rgb(79, 79, 79);
        }
      &:checked:after{
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #035ca7;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 1px solid rgb(79, 79, 79);
      }
    }
   }
  }
}
.pop__up {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding-top: 80px;
  background-color: rgba($color: black, $alpha: 0.8);
  padding-left: 12px;
  padding-right: 12px;
  h1, h2{
    font-family: Typewriter,serif !important;
  }
  .icon{
    &.ticket{
      background-color: #035CA7;
      margin-top: 2rem !important;
      position: relative;
      font-family: "DINPro", sans-serif;
      font-weight: bolder;
      font-size: 16px;
      height: 48px;
      border-radius: 5px;
      .sub-btn{
        color: #f0f1f3;
        text-decoration: none;
      }
    }
  }
  .icon img {
    width: 100%;
  }


  .content {
    background-image: url('../static/images/account-info/tab-content-body.jpg');
    background-position: right;
    background-size: contain;
    border: 10px solid;
    border-image: repeating-linear-gradient(
        -45deg,
        #afa758,
        #afa758 2.5%,
        #000 0,
        #000 5%
      )
      10;
    padding: 36px;
    text-align: center;
    border-radius: 5px;
    .header {
      font-family: BohemianTypewriter, serif;
      font-weight: normal;
    }
    .update-btn{
      background-color: #035ca7;
      border:none;
      color:white;
      margin-top: 36px;
      position: relative;
      height: auto;
      width: 226px;
      max-width: 100%;
      line-height: 24px;
      text-align: center;
      vertical-align: middle;
      user-select: none;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 500;
      text-transform: uppercase;
      padding: 11px 30px;
      border-radius: 8px;
      white-space: nowrap;
    }

    
  }
  .close {
    position: relative;
    top: 15px;
    float: right;
    right: 15px;
  }
}

.subscriptions-purchase-disabled {
  padding-top: 200px; 
}