.post-it.top.paper.L {
  img {
    width: 100%;
  }
}

.home-wrapper {
  margin-top: 50px !important;
}

.container{
  margin-top: 50px;
}