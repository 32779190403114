.ui.header {
  p {
    font-size: 20px!important;
    line-height: 20px!important;

    img,iframe {
      width: 100%;
    }
  }
}

.title-column{
  p {
    img,iframe {
      width: 100%;
    }
  }
}

.excerpt-content{
  text-align: justify;
  hr {
    max-width: 100%!important;
  }
}

.news-content{
  padding: 12px;
}

.ui.header {
  font-size: 20px!important;
  line-height: 20px!important;
  overflow: hidden;
}

.article-text {
  font-size: 20px;
}
