$borderColor: rgba($color: #000000, $alpha: 0.4);
.news-navbar-wrapper {
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
}
.news-navbar {
  margin-bottom: 0px !important;
  font-family: Georgia,Times,"Times New Roman" !important;
  margin-top: 0px !important;
  border-bottom: 1px solid $borderColor !important;
  border-radius: 0 !important;
  background: url("../static/images/news/newspaper-bg-tile.png")!important;
  &.navigation.dark-mode.menu{
    div.item{
      border-right: 1px solid  #b3b3b3;
    }
  }
  &.dark-mode{
    border-bottom: 1px solid #b3b3b3;
    @include background-colors($light-theme:false);
    &:before{
      display: none;
    }
    border-bottom: 1px solid #b3b3b3;
    .menu{
      border: 1px solid  #b3b3b3;
      div.item{
        &:last-child{
          border-right:none;
        }
        &.active{
          background: #ffffff33;
        }
       
        border-right: 1px solid  #b3b3b3;
      }
    }
    >*, div, p, a{
        @include theme-colors($light-theme: false);
    }
    .link {
      &.selected{
           color:black !important;
        }
    }
    .menu-icon{
      filter: brightness(1) invert(1);
    }
  } 
  .menu-icon{
    height: 45px;
  }
  &.menu{
    width: 100%;
    &.dark-mode{
      @include background-colors($light-theme:false);
      border: 1px solid #b3b3b3;
      >*, div, p, a{
          @include theme-colors($light-theme: false);
      }
      .link{
          .highlighted{
             color:black !important;
          }
      }
  }  
    .grid {
      margin: 0!important;
      width: 100%;
    }
  }

  &.header{
    padding-top: 6px;
    padding-bottom: 6px;
    &::before {
      content: "";
      height: 8px;
      width: 100%;
      top:0;
      left: 0;
      background-size: 25px 8px;
      background: url("../static/images/news/newspaper-edge-top.png");
      background-repeat: repeat-x;
      background-position: top;
      position: absolute;
    }
  }

  &.date {
    font-size: 11px;
    // height: 38px;
    .grid .row{
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.navigation {
    overflow-x: scroll;
    max-height: 70px;
    padding-bottom: 0px;
    padding-top: 6px;
    &::-webkit-scrollbar-track {
      width: 0!important; /* Remove scrollbar space */
      height: 0!important;
      background: none !important; /* Optional: just make scrollbar invisible */
    }
    &::-webkit-scrollbar-thumb {
      width: 0; /* Remove scrollbar space */
      height: 0;
      background: none !important; /* Optional: just make scrollbar invisible */
    }
    &::-webkit-scrollbar{
      height: 0px;
    }
    .item {
      padding-left: 4px;
      padding-right: 4px;
      width: auto;
      height: 26px;
      &::before{
        background: $borderColor;
      }
      .link {
        &.selected{
          background: rgba($color: #000000, $alpha: 0.5);
        }
        p {
          font-size: 11px;
          padding: 0;
          line-height: 16px;
          margin-bottom: 0px;
          float: left;
          margin: 0 8px;
          &.highlighted {
            background: yellow;
          }
        }
      }
    }
  }
  .grid {
    padding: 0px;
  }
}
